import { TextInput } from "pepsico-ds";
import * as React from "react";
import "./text-input.scss";

const IntergerOrNullInput = ({
  value,
  updateValue,
  label,
  errors,
  schema,
  uiSchema,
  required,
  disabled,
  visible,
}) => {
  const inputRef = React.useRef();
  const [isFocused, setIsFocused] = React.useState(false);
  const errMsg =
    schema.message && errors.indexOf("must match pattern") > -1
      ? schema.message
      : errors;

  const isValidLength = (val, maxLength) => !maxLength || val.length <= maxLength;

  const parseValue = (val) => {
    let parsedVal = parseFloat(val);
    if (parsedVal < 0 || Number.isNaN(parsedVal)) {
      parsedVal = 0;
    }
    return parsedVal;
  };

  const handleNumberOrIntegerChange = (val, schema, uiSchema) => {
    if (!isValidLength(val, uiSchema.maxLength)) {
      return 0;
    }
    return parseValue(val);
  };

  const onChange = (val) => {
    val = handleNumberOrIntegerChange(val, schema, uiSchema);
    updateValue(val);
  };

  const diableButton = disabled || uiSchema.readOnly ? "disable-button" : "";
  // Display info icon with info text
  if (inputRef.current && uiSchema?.info) {
    const infoIcon =
      inputRef.current?.parentElement?.previousSibling?.getElementsByClassName(
        "material-icons-outlined"
      );
    if (infoIcon?.length && infoIcon?.[0]) {
      infoIcon[0].title = uiSchema.info;
    }
  }

  return visible ? (
    <>
      <div className="input-text-wrapper">
        <TextInput
          required={required}
          className={`${uiSchema?.className ?? ""} ${diableButton} inputType-number`}
          disabled={disabled || uiSchema.readOnly || uiSchema.disabled}
          maxLength={uiSchema.maxLength ?? ""}
          type="number"
          error={isFocused && !disabled && errMsg ? errMsg : undefined}
          label={label}
          info={uiSchema?.info || ""}
          showLabelIcon={!!uiSchema?.info}
          ref={inputRef}
          onUpdate={onChange}
          onBlur={() => {
            !isFocused && setIsFocused(true);
          }}
          value={!value ? "0" : value}
          placeholderText={uiSchema.placeholder ?? ""}
          readOnly={uiSchema.readonly}
          helperText={uiSchema.helperText}
          min={uiSchema.min}
        />
      </div>
    </>
  ) : null;
};

export default IntergerOrNullInput;
