import { useQuery } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { Radio } from "@mui/material";
import {
  ButtonGroup,
  InputItem,
  LoadingSpinner,
  Modal,
  Pagination,
} from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { listSweepstakesActive } from "../../../graphql/queries/listSweepstakesActive";
import { convertDateToLocaleTz, getActiveDate } from "../../../utils/commons";
import { searchHandler } from "../../../utils/functions";
import "./sweepstakesSelectComponent.scss";

const SweepstakesSelectComponent = ({ data, path, handleChange }) => {
  const { programTimezone } = useContext(GlobalConfigContext);
  const { data: queryData, loading } = useQuery(listSweepstakesActive);

  const [selectedValue, setSelectedValue] = useState(data ?? null);
  const [editSweepstakesModalOpen, setEditSweepstakesModalOpen] = useState(false);

  const [activeSweepstakesList, setActiveSweepstakesList] = useState([]);
  const [currentActiveSweepstakesList, setCurrentActiveSweepstakesList] = useState(
    []
  );

  const [pagination, setPagination] = useState({
    recordsPerPage: 5,
    currentPage: 1,
  });

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const getPaginatedSweepstakes = () => {
    const start = (pagination.currentPage - 1) * pagination.recordsPerPage;
    const end = start + pagination.recordsPerPage;
    return currentActiveSweepstakesList.slice(start, end);
  };

  useEffect(() => {
    if (queryData?.listSweepstakes) {
      const activeSweepstakes = [...queryData.listSweepstakes].sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      setActiveSweepstakesList(activeSweepstakes ?? []);
      setCurrentActiveSweepstakesList(activeSweepstakes ?? []);
      const targetIndex = activeSweepstakes.findIndex(
        (item) => item.id === selectedValue
      );
      if (targetIndex !== -1) {
        // Calculate the page number
        const { recordsPerPage } = pagination;
        const pageNumber = Math.floor(targetIndex / recordsPerPage) + 1;
        // Update the pagination state with the new current page
        setPagination((prev) => ({ ...prev, currentPage: pageNumber }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const handleSearch = (event) => {
    const filteredList = searchHandler(
      event.target.value,
      ["title"],
      activeSweepstakesList
    );
    setCurrentActiveSweepstakesList(filteredList);
  };

  const handleSelectedSweepstake = () => {
    return (
      activeSweepstakesList.filter((sweepstake) => sweepstake.id == selectedValue)[0]
        ?.title || ""
    );
  };

  const toggleEditSweepstakesModal = () => {
    setEditSweepstakesModalOpen(!editSweepstakesModalOpen);
  };

  const updateSelectedSweepstake = () => {
    setEditSweepstakesModalOpen(false);
    handleChange(path, selectedValue);
  };

  return (
    <div className="sweepstake-select">
      <div
        className={`sweepstake-item display-flex flex-direction-row font-xs font-bold sweepstake-added`}
      >
        <div className="added-sweepstake display-flex flex-direction-row align-items-center justify-content-space-between">
          <span>Sweepstakes: {handleSelectedSweepstake()}</span>
          <ButtonGroup
            size="small"
            itemCount={3}
            items={[
              {
                icon: "edit",
                onClick: toggleEditSweepstakesModal,
              },
              {
                icon: "delete",
                disabled: true,
              },
            ]}
          />
        </div>
      </div>

      <Modal
        key="add-rule-modal"
        type="M"
        showModal={editSweepstakesModalOpen}
        onCloseModal={toggleEditSweepstakesModal}
        title={"Select Sweepstakes"}
        showTertiaryButton={false}
        showLink={false}
        secondaryButtonProps={{
          size: "medium",
          text: "Cancel",
          variant: "secondary",
          onClick: toggleEditSweepstakesModal,
        }}
        primaryButtonProps={{
          size: "medium",
          text: "Add",
          variant: "primary",
          disabled: selectedValue !== null ? false : true,
          onClick: updateSelectedSweepstake,
        }}
      >
        <div style={{ width: "75%" }}>
          <div
            style={{
              padding: "0px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "16px",
              marginBottom: "0.85rem",
              flex: 0.2,
            }}
          >
            <InputItem
              placeholder="Type a search keyword..."
              trailingIcon="search"
              size="medium"
              className="input-search"
              onChange={handleSearch}
            />
          </div>

          <div>
            <div className="sweekstakes-table p-0">
              {loading ? (
                <LoadingSpinner
                  size={{
                    height: "30px",
                    width: "30px",
                  }}
                  style={{ margin: "auto" }}
                />
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th className="column-checkbox"></th>
                      <th className="column-brands">SWEEPSTAKES NAME</th>
                      <th className="column-keywords">STATUS</th>
                      <th className="column-keywords">ACTIVE DATES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getPaginatedSweepstakes()?.length ? (
                      getPaginatedSweepstakes()?.map((sweepstakesList) => (
                        <tr key={sweepstakesList.id}>
                          <td className="td-checkbox">
                            <Radio
                              checked={selectedValue === sweepstakesList.id}
                              onChange={handleRadioChange}
                              value={sweepstakesList.id}
                              name="radio-buttons"
                              size="small"
                            />
                          </td>
                          <td className="td-brands">{sweepstakesList.title}</td>
                          <td className="td-brands">
                            <div className="common-status-wrapper">
                              <div
                                className={`status ${
                                  sweepstakesList.campaign?.campaignStatus.toLowerCase() ??
                                  "active"
                                }`}
                              >
                                <span>
                                  {sweepstakesList.campaign?.campaignStatus.toUpperCase() ??
                                    "Active"}{" "}
                                </span>
                              </div>
                            </div>
                          </td>
                          {sweepstakesList?.startsAt &&
                            sweepstakesList?.endsAt &&
                            programTimezone && (
                              <td className="td-keywords">
                                {sweepstakesList?.startsAt && sweepstakesList?.endsAt
                                  ? getActiveDate(
                                      convertDateToLocaleTz(
                                        sweepstakesList?.startsAt,
                                        programTimezone
                                      ),
                                      convertDateToLocaleTz(
                                        sweepstakesList?.endsAt,
                                        programTimezone
                                      )
                                    )
                                  : "-"}
                              </td>
                            )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="no-data">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
            {currentActiveSweepstakesList.length > 0 && (
              <Pagination
                currentPage={pagination.currentPage}
                onUpdate={(page) => {
                  setPagination((prev) => ({ ...prev, currentPage: page }));
                }}
                pageCount={Math.ceil(
                  currentActiveSweepstakesList.length / pagination.recordsPerPage
                )}
                size="medium"
                variant="number"
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const SweepstakesSelectComponentControl = withJsonFormsControlProps(
  SweepstakesSelectComponent
);
