import { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import RewardItem from "./RewardItem";
import "./SelectedRewards.scss";

const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#e9ebf0" : "#fff",
  width: "100%",
  position: "relative",
});

export default function SelectedRewards({
  value = [],
  onRewardSelect,
  updateRewardData,
  type,
  handleOnDragEnd,
  activeStartDate,
}) {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <StrictModeDroppable droppableId="rewards" data-testid="dropable-rewards">
        {(provided, snapshot) => (
          <div
            className="p-3"
            style={{
              backgroundColor: "#F3F7FD",
              borderRadius: "8px",
              overflowY: "auto",
              ...getListStyle(snapshot.isDraggingOver),
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <h5 className="$font-xsmall-bold">
              {value?.length || 0} selected rewards
            </h5>
            {value?.map((reward, index) => (
              <RewardItem
                key={reward.rewardId}
                reward={reward}
                index={index}
                onRewardSelect={onRewardSelect}
                updateRewardData={updateRewardData}
                type={type}
                activeStartDate={activeStartDate}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
}
