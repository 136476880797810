import { getDateWithFormat } from "../../../utils/commons";
const recieptTitle = {
  end_timestamp: "Receipt End Date",
  start_timestamp: "Receipt Start Date",
};

const ReceiptDateComponent = (val) => {
  const schema = val?.inputSchema;
  const data = val?.data;
  const reversedItems = Object?.entries(schema?.properties)
    .map((item) => item)
    .reverse();

  return (
    <>
      {reversedItems?.map(([key, propertySchema]) => {
        const title = recieptTitle[key] || key;
        const value = data[key];
        if (propertySchema.hidden === true) {
          return <></>;
        }
        return (
          <div key={key}>
            <div className="field-pair">
              <span className="field-label">
                {title}:{" "}
                {getDateWithFormat(
                  value,
                  val?.currentProgramTimezone,
                  "Do MMMM YYYY hh:mm A"
                )}
              </span>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ReceiptDateComponent;
