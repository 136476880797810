import axios from "axios";
import { useContext } from "react";
import { GlobalConfigContext } from "../../../../context/GlobalConfigContext";
import { sampleText } from "../../partnerCodeGroupSummary/sampleFile";

export function usePartnerCodeGroupForm() {
  const { globalState } = useContext(GlobalConfigContext);
  const accessToken = JSON.parse(localStorage.getItem("okta-token-storage"))
    ?.accessToken?.accessToken;
  const programId =
    globalState?.programConfig?.id || "018a2bd5-fdc2-77ce-9367-7d700186d5ae";
  const partnerId = localStorage.getItem("partnerId");

  const handleError = (message, error) => {
    console.error(message, error);
    return {
      status: false,
      message: message,
    };
  };

  const getAccessToken = () => accessToken || localStorage.getItem("accessToken");

  const getPartnerId = () => partnerId || localStorage.getItem("partnerId");

  const handleDeleteFile = async (rewardItemFileData, fileId) => {
    const file = rewardItemFileData[fileId];
    if (!file) {
      return handleError("File not found.");
    }

    const fileName = file.name?.split("/")[1] || file?.name;
    if (!fileName) {
      return handleError("Invalid file name format.");
    }

    const uploadUrl = window.REWARD_PARTNER_GROUP_FILE_MANAGER_BASE_URI;
    const programName = window.REACT_APP_PROGRAM;
    const endpoint = `${uploadUrl}/${programName}/${programId}/${partnerId}/${fileName}`;

    try {
      const response = await axios.delete(endpoint, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });

      if (response.status === 204) {
        return {
          status: true,
          message: "File Deleted Successfully",
        };
      } else {
        return handleError("Failed to delete file. Please try again.");
      }
    } catch (error) {
      return handleError("An error occurred while deleting the file.", error);
    }
  };

  const handleUploadFile = async (files) => {
    const formData = new FormData();
    Array.from(files).forEach((file) => {
      if (file?.id == null) formData.append("file", file);
    });

    try {
      const uploadUrl = window.REWARD_PARTNER_GROUP_FILE_MANAGER_BASE_URI;
      const endpoint = `${uploadUrl}upload?program_id=${programId}&partner_id=${getPartnerId()}`;

      const response = await axios.post(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const uploadedFileData = response.data.map((currentFile) => ({
        id: currentFile.id,
        name: currentFile.name,
        size: currentFile.size,
        type: currentFile.type,
        url: currentFile.url || null,
        metadata: {
          description: currentFile.metadata.description,
          tags: currentFile.metadata.tags,
          uploadedBy: currentFile.metadata.uploaded_by,
          uploadedAt: currentFile.metadata.uploaded_at,
          containerName: currentFile.metadata.container_name,
        },
      }));

      return {
        status: true,
        data: uploadedFileData,
      };
    } catch (error) {
      const errMsg =
        error?.response?.data?.extension?.issues?.[0]?.detail ||
        error?.response?.data?.extension?.issues?.detail ||
        "Something went wrong";

      return handleError("Upload error: " + errMsg, error);
    }
  };

  const handleDownloadFile = async () => {
    try {
      const blob = new Blob([sampleText()], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "sampleFile.txt";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return null;
    } catch (error) {
      return `There was an error downloading the file: ${error}`;
    }
  };

  return {
    handleDeleteFile,
    handleUploadFile,
    handleDownloadFile,
  };
}
