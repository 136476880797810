export const CODE_GROUP_LIFE_CYCLE_STATUS = {
  DRAFT: "DRAFT",
  QUEUED: "QUEUED",
  IN_PROGRESS: "IN_PROGRESS",
  GENERATED: "GENERATED",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  EXPIRED: "EXPIRED",
};

export const CODE_GROUP_ADMINISTRATION_STATUS = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
};

export const PRODUCER_COMPANIES = {
  HAS: "HAS",
  BERICAP: "BERICAP",
  PROPAK: "PROPAK",
  BALL: "BALL",
  CROWN: "CROWN",
};
