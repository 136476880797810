import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    CustomerSupport: require("../locales/en/CustomerSupport.json"),
  },
  "tr-TR": {
    CustomerSupport: require("../locales/tr-TR/CustomerSupport.json"),
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: process.env.REACT_APP_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    resources,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
