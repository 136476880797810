import { useQuery } from "@apollo/client";
import { Grid } from "pepsico-ds";
import { useEffect, useState } from "react";
import { ActivityOutcomesSummary } from "../../../../components/campaign/ActivitySetup/ActivityOutcomesSummary";
import ViewComponentsRenderer from "../../../../components/campaign/viewComponents/ViewComponentsRenderer";
import { listSweepstakesActive } from "../../../../graphql/queries/listSweepstakesActive";
import { getTargetId } from "../../../../utils/commons";
import { FeatureToggle, isFeatureEnabled } from "../../../../utils/featureToggle";
import SummaryCard from "./SummaryCard";

const Activity = ({ data, length }) => {
  return (
    <>
      {length > 1 && (
        <h3 className="campaign-definition-name">
          {data?.activityDefinition?.name}
        </h3>
      )}
      <Grid
        item
        xs={12}
        md={6}
        xl={4}
        mt={1}
        mb={1}
        className="display-flex gap-3 two-column"
      >
        <ViewComponentsRenderer
          customComponentName={data?.activityDefinition?.slug}
          data={data?.activityData}
          inputSchema={data?.activityDefinition?.inputSchema}
        />
      </Grid>
    </>
  );
};
const Rules = ({ data }) => (
  <>
    {/* <h3 className="campaign-name">Rules</h3> */}
    {data?.map((rule) => (
      <Grid
        key={rule?.ruleDefinition?.slug}
        item
        xs={12}
        md={6}
        xl={4}
        mt={1}
        mb={1}
        className="display-flex gap-3 two-column"
      >
        <ViewComponentsRenderer
          customComponentName={rule.ruleDefinition?.slug}
          data={rule.ruleData}
          inputSchema={rule.ruleDefinition?.inputSchema}
        />
      </Grid>
    ))}
  </>
);
const Outcomes = ({ data, sweepstakeTittle }) => {
  // Add the outcome name outcomeRules
  const updatedData = data.map((outcome) => ({
    ...outcome,
    outcomeData: {
      ...outcome?.outcomeData,
      ...(outcome?.outcomeDefinition.slug == "latam-award-sweepstakes-entry" &&
        outcome?.outcomeData?.target_id != null && {
          sweepstakes: sweepstakeTittle,
        }),
    },
    outcomeRules: outcome?.outcomeRules?.map((rule) => ({
      ...rule,
      name: outcome.outcomeDefinition.name,
    })),
  }));
  return (
    <>
      <h3 className="campaign-name">Outcomes</h3>
      {updatedData
        .reduce(
          (acc, outcome) => [
            ...acc,
            ...outcome.outcomeRules,
            {
              ruleData: outcome.outcomeData || {},
              ruleDefinition: outcome.outcomeDefinition || {},
            },
          ],
          []
        )
        .filter((rule) => rule?.ruleData?.target_id !== null)
        .map((rule) => (
          <>
            {rule?.name && (
              <h3 className="activity-definition-name ">{rule?.name}</h3>
            )}
            <div className="ml-3">
              <Grid
                key={rule?.ruleDefinition?.slug}
                item
                xs={12}
                md={6}
                xl={4}
                mt={1}
                mb={1}
                className="display-flex gap-3 two-column"
              >
                {!!Object.keys(rule.ruleData).length && (
                  <ViewComponentsRenderer
                    customComponentName={rule.ruleDefinition?.slug}
                    data={rule.ruleData}
                    inputSchema={rule.ruleDefinition?.inputSchema}
                  />
                )}
              </Grid>
            </div>
          </>
        ))}
    </>
  );
};

const SummaryActivitySetup = ({ data, isViewScreen, editSectionHandler }) => {
  const { data: queryData } = useQuery(listSweepstakesActive);
  const [title, setTitle] = useState("");

  useEffect(() => {
    let title = updateSweepstakeValue(queryData, data);
    setTitle(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const updateSweepstakeValue = (queryData, data) => {
    if (queryData?.listSweepstakes) {
      let fetchTargetID = data.activities.map((activity) =>
        getTargetId(activity.outcomes)
      );

      let title =
        queryData?.listSweepstakes.filter(
          (sweepstake) => sweepstake?.id === fetchTargetID[0]
        )[0]?.title || "";

      return title;
    }
  };

  return (
    <SummaryCard
      title="Activity Setup"
      isViewScreen={isViewScreen}
      handleEditSection={editSectionHandler}
    >
      {data?.name && <h3 className="campaign-name">{data?.name}</h3>}
      {data.activities?.map((activity) => {
        const isAcitivtyEmpty =
          (activity?.activityDefinition?.rules?.length === 0 &&
            activity.activityDefinition?.outcomes?.length === 0 &&
            Object.keys(activity.activityDefinition?.uiSchema || {}).length === 0) ||
          false;
        if (isAcitivtyEmpty) {
          return (
            <h3
              key={activity?.activityDefinition?.name}
              className="campaign-definition-name"
            >
              {activity.activityDefinition.name}
            </h3>
          );
        }
        return (
          <div className="mb-20" key={activity?.activityDefinition?.name}>
            {activity?.activityDefinition?.name && (
              <h3 className="campaign-name fs-14">
                {activity?.activityDefinition?.name}
              </h3>
            )}
            <div className="ml-3">
              {isFeatureEnabled(FeatureToggle.showActivityIdAtSummaryPage) &&
                activity?.id && (
                  <div key={activity?.id} className="additional-fields-container">
                    <div className="field-pair">
                      <span className="field-label">Activity ID:</span>
                    </div>
                    <div className="field-pair-two-column">
                      <span className="field-value">{activity?.id}</span>
                    </div>
                  </div>
                )}
              <Grid container>
                {activity?.activityData &&
                  activity?.activityDefinition?.inputSchema &&
                  activity?.activityDefinition?.uiSchema && (
                    <Activity data={activity} length={data.activities?.length} />
                  )}

                {/* Render activityRules */}
                {activity?.activityRules?.length > 0 && (
                  <Rules data={activity.activityRules} />
                )}
              </Grid>
            </div>
            {/* Render activities outcomes */}
            {activity?.outcomes?.length > 0 &&
              (isFeatureEnabled(
                FeatureToggle.isDetailedOutcomeEnabledInSummaryPage
              ) ? (
                <ActivityOutcomesSummary data={activity.outcomes} />
              ) : (
                <Outcomes data={activity.outcomes} sweepstakeTittle={title} />
              ))}
          </div>
        );
      })}
    </SummaryCard>
  );
};

export default SummaryActivitySetup;
