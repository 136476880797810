import ViewComponentsRenderer from "../../../components/campaign/viewComponents/ViewComponentsRenderer";
import { isEmptyObject } from "../../../utils/commons";
import "./activitySetup.scss";

export const ActivityOutcomesSummary = ({ data }) => {
  // Render outcome data based on inputSchema properties
  const renderOutcomeData = (outcome) => {
    return (
      <div className="ml-3">
        <ViewComponentsRenderer
          customComponentName={outcome.outcomeDefinition?.slug}
          data={outcome.outcomeData}
          inputSchema={outcome.outcomeDefinition?.inputSchema}
        />
      </div>
    );
  };

  // Render outcome rules
  const renderOutcomeRules = (outcomeRules) => {
    return outcomeRules.map((rule) => (
      <div className="ml-3" key={rule.id}>
        {Object.keys(rule.ruleData).map((key) => {
          return (
            <ViewComponentsRenderer
              key={key}
              customComponentName={rule.ruleDefinition?.slug}
              data={rule.ruleData}
              inputSchema={rule.ruleDefinition?.inputSchema}
            />
          );
        })}
      </div>
    ));
  };

  // Main rendering of outcomes
  return (
    <div className="display-flex flex-direction-column gap-2">
      {data?.map((outcome) => (
        <div key={outcome.id}>
          {/* Outcome title */}
          <div className="outcome-title-container">
            <h3 className="activity-definition-name ">
              {outcome?.outcomeDefinition?.name ?? outcome.name}
            </h3>
          </div>

          {/* Outcome data */}
          {!isEmptyObject(outcome?.outcomeDefinition?.uiSchema) && (
            <div>
              <div className="display-flex flex-direction-column">
                {Object.keys(outcome?.outcomeDefinition?.uiSchema).length > 0 &&
                Object.keys(outcome?.outcomeDefinition?.inputSchema).length > 0 ? (
                  renderOutcomeData(outcome)
                ) : (
                  <h5>No Data</h5>
                )}
              </div>
            </div>
          )}

          {/* Outcome rules */}
          {outcome?.outcomeRules && renderOutcomeRules(outcome.outcomeRules)}
        </div>
      ))}
    </div>
  );
};
