import { withJsonFormsControlProps } from "@jsonforms/react";
import { useContext } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import DatePickerMaterialUIField from "./DatePickerMaterialUIField";

const DatePickerMaterialUIComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    visible,
    enabled,
  } = props;

  const {
    globalState: { programConfig },
  } = useContext(GlobalConfigContext);
  const currentProgramTimezone = programConfig?.localization?.programTimezone;

  return (
    <DatePickerMaterialUIField
      value={data}
      label={label}
      updateValue={(newVal) => handleChange(path, newVal)}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
      visible={visible}
      disabled={!enabled || uischema?.disabled}
      readOnly={uischema?.readOnly}
      timezone={currentProgramTimezone}
    />
  );
};

export const DatePickerMaterialUIControl = withJsonFormsControlProps(
  DatePickerMaterialUIComponent
);
