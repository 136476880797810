export const FeatureToggle = {
  memberListExtra: "REACT_APP_MEMBER_LIST_EXTRA_FIELDS",
  showActivityIdAtSummaryPage: "SHOW_ACTIVITY_ID_AT_SUMMARY_PAGE",
  isDetailedOutcomeEnabledInSummaryPage:
    "IS_DETAILED_OUTCOME_ENABLED_IN_SUMMARY_PAGE",
  sweepstakesInCampaignSummary: "SWEEPSTAKES_ACTIVITY_IN_CAMPAIGN_SUMMARY",
  rewardCategoriesInCampaignSummary: "REWARD_CATEGORIES_IN_CAMPAIGN_SUMMARY",
  showCampaignCardAfterActivityCompletionText:
    "SHOW_CAMPAIGN_CARD_AFTER_ACTIVITY_COMPLETION_TEXT",
  showNumberOfEntriesInCampaignCardText:
    "SHOW_NUMBER_OF_ENTRIES_IN_CAMPAIGN_CARD_TEXT",
  popoverSetupInCampaignSummary: "POPOVER_SETUP_IN_CAMPAIGN_SUMMARY",
  walletSetupInCampaignSummary: "WALLET_SETUP_IN_CAMPAIGN_SUMMARY",
};

export const isFeatureEnabled = (flagKey) => {
  const ftValue = window?.[flagKey];
  if (ftValue === undefined) {
    console.warn(`[FT] Feature "${flagKey}" is not defined!`);
  }
  return ftValue == "true";
};
