import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
} from "@mui/material";
import { Button } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { EditWidgetContext } from "../../../../context/EditWidgetContext";
import LoaderContext from "../../../../context/LoaderContext";
import JsonFormsWrapper from "../../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import { SystemCard } from "../SystemCard/SystemCard";
import "./EditWidget.scss";
import StrictModeDroppable from "./StrictModeDroppable";

function EditWidget({ editWidgetModalOpen, toggleEditWidgetModal }) {
  const {
    initialState,
    widgetState,
    load,
    jsonSchema,
    uiSchema,
    errMsg,
    successMsg,
    setSuccessMsg,
    setErrMsg,
    setWidgetState,
    handleSaveEditWidget,
  } = useContext(EditWidgetContext);
  const [flag, setFlag] = useState(false);
  const [validIntegerInputs, setValidIntegerInputs] = useState(true);
  const { setIsLoading } = useContext(LoaderContext);
  const [dragFlag, setDragFlag] = useState(false);
  const [draggableList, setDraggableList] = useState(["survey_card", "email_card"]);

  const validateWidget = () => {
    const widgetStateCopy = { ...widgetState };
    const val = Object.keys(widgetStateCopy).filter((key) => {
      if (
        (widgetStateCopy[key].display &&
          (!widgetStateCopy[key].position ||
            widgetStateCopy[key].position <= 0 ||
            isNaN(widgetStateCopy[key].position) ||
            widgetStateCopy[key].position === "")) ||
        (widgetStateCopy[key].display &&
          key !== "survey_card" &&
          key !== "collect_and_win_pending_completion_card" &&
          key !== "po1_pending_completion_card" &&
          (!widgetStateCopy[key].number_of_days_to_show ||
            widgetStateCopy[key].number_of_days_to_show <= 0 ||
            isNaN(widgetStateCopy[key].number_of_days_to_show) ||
            widgetStateCopy[key].number_of_days_to_show === ""))
      ) {
        return widgetStateCopy[key];
      }
    });
    if (val.length > 0) {
      const defaultArray = [
        "survey_card",
        "collect_and_win_pending_completion_card",
        "po1_pending_completion_card",
      ];
      const success = val.every((e) => defaultArray.includes(e));
      setErrMsg([
        success
          ? `Please enter valid Position for ${val[0]?.replaceAll("_", " ")}`
          : `Please enter valid Position and No. of days to show for ${val[0]?.replaceAll("_", " ")}`,
      ]);
      setFlag(false);
      return true;
    }
  };

  const handleEdit = () => {
    setFlag(true);
    const isValid = validateWidget();
    if (isValid) {
      return;
    }
    handleSaveEditWidget();
  };

  const handleCloseSnackbar = () => {
    setErrMsg([]);
  };

  const validateIntegerInputs = (widget) => {
    for (const card in widget) {
      if (
        widget[card]?.position === "" ||
        widget[card]?.number_of_days_to_show === ""
      ) {
        return false;
      }
    }
    return true;
  };

  const handleWidget = (data) => {
    setDragFlag(false);
    setValidIntegerInputs(validateIntegerInputs(data));
    setWidgetState((prev) => {
      const getDifference = (a, b) =>
        Object.fromEntries(
          Object.entries(b).filter(([key, val]) => key in a && a[key] !== val)
        );

      const updatedObject = getDifference(prev, data);
      if (Object.keys(updatedObject)?.length > 0) {
        Object.keys(updatedObject).forEach((key) => {
          const val = Object.entries(prev).filter(
            (item) =>
              item[0] !== key &&
              parseInt(item[1]?.position) === parseInt(data[key]?.position)
          );
          if (data[key].position > 6 || data[key].position < 0 || val.length > 0) {
            data[key].position = null;
            setErrMsg([
              `Position is already assigned to ${val[0][0].replaceAll("_", " ")}`,
            ]);
          }
        });
      }
      return { ...prev, ...data };
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    setDragFlag(true);
    const items = Array.from(draggableList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    let widgetStateCopy = { ...widgetState };
    // Create a deep copy of widgetStates
    items.forEach((item, index) => {
      widgetStateCopy[item].position = index + 1;
    });
    setWidgetState(widgetStateCopy);
    // Update the state with the reordered items
    setDraggableList(items);
  };

  const renderItem = (item, index) => (
    <Draggable key={item} draggableId={item} index={index}>
      {(provided) => (
        <div
          className="item"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <SystemCard widgetName={item} />
        </div>
      )}
    </Draggable>
  );

  useEffect(() => {
    if (successMsg === "success") {
      toggleEditWidgetModal();
      setSuccessMsg("");
    }
  }, [successMsg]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(load);
  }, [load]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (successMsg === "success" && errMsg?.length === 0) {
      setFlag(false);
    }
    if (errMsg?.length > 0) {
      setFlag(false);
    }
  }, [errMsg, successMsg]);

  useEffect(() => {
    if (!dragFlag) {
      let draggableList = { ...widgetState };
      draggableList = Object.entries(draggableList)
        .filter((item) => item[1]?.display && item[1]?.position > 0)
        .sort((a, b) => (a[1]?.position > b[1]?.position ? 1 : -1))
        .map((item) => item[0]);

      setDraggableList(draggableList);
    }
  }, [widgetState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Dialog
        className="widget-modal"
        open={editWidgetModalOpen}
        scroll="paper"
        sx={{ "& .MuiPaper-root": { width: "40%" } }}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" direction="row">
            <Grid item xs={10}>
              <h2
                style={{
                  marginLeft: "18%",
                  textAlign: "center",
                  paddingBottom: "2%",
                }}
              >
                Widgets on Home Screen{" "}
              </h2>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="flex-end">
              <CloseIcon
                onClick={() => {
                  setWidgetState(initialState);
                  toggleEditWidgetModal();
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ overflowX: "hidden" }}>
          <div className="widget-mod">
            {jsonSchema && uiSchema && (
              <JsonFormsWrapper
                data-testid="json-forms-wrapper"
                jsonschema={jsonSchema}
                uischema={uiSchema}
                data={widgetState}
                onChange={(data) => handleWidget(data)}
              />
            )}
          </div>
          <h3 style={{ paddingBottom: "2%" }}>
            Drag the cards to adjust display order
          </h3>
          <div
            style={{ display: "flex" }}
            className="dragdrop-container"
            data-testid="drag-drop-context"
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="dragabble-wrap p-0">
                {draggableList.length > 0 && (
                  <StrictModeDroppable
                    droppableId="droppableList"
                    direction="horizontal"
                  >
                    {(provided) => (
                      <div
                        className="dragabble-list"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          overflowY: "auto",
                          maxHeight: "400px",
                          display: "flex",
                        }}
                      >
                        {draggableList.map(renderItem)} {provided.placeholder}
                      </div>
                    )}
                  </StrictModeDroppable>
                )}
              </div>
            </DragDropContext>
          </div>
          <div className="bottom-button">
            <Button onClick={handleEdit} disabled={flag || !validIntegerInputs}>
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={errMsg?.length > 0}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
          {errMsg[0]}
        </Alert>
      </Snackbar>
    </>
  );
}

export default EditWidget;
