import { useQuery } from "@apollo/client";
import { LoadingSpinner } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../components/common/GridCard.scss";
import "../../../../components/common/campaignCard/CampaignCard.scss";
import { CampaignSetupContext } from "../../../../context/CampaignSetupContext";
import { getCampaignListing } from "../../../../graphql/queries/getCampaignListing";
import { listSegments } from "../../../../graphql/queries/listSegments";
import { loadCampaignById } from "../../../../graphql/queries/loadCampaignById";
import { paths } from "../../../../routes/paths";
import CampaignOutcomes from "./CampaignOutcomes";
import LocalizationList from "./LocalizationList";
import ProductContentList from "./ProductContentList";
import SegmentsLinkedCampaigns from "./SegmentsLinkedCampaigns";
import SummaryActivitySetup from "./SummaryActivitySetup";
import SummaryBasicSetup from "./SummaryBasicSetup";
import SummaryCard from "./SummaryCard";
import "./summaryPage.scss";

export default function SummaryPage({ campaignId, isOnPage = true }) {
  const { campaignState, setCampaignState, currentCampaignTypeConfig } =
    useContext(CampaignSetupContext);
  const [staticCampaignState, setStaticCampaignState] = useState({});
  const [campaignRulesData, setCampaignRulesData] = useState([]);
  const [segmentedContentsData, setSegmentedContentsData] = useState([]);
  const inputSchema = currentCampaignTypeConfig?.campaignSetup?.inputSchema || {};
  const contentSchema =
    currentCampaignTypeConfig?.contentSteps[0]?.inputSchema || {};
  const navigate = useNavigate();
  const location = useLocation();

  const { data: allCampaignsData, loading: loadingCampaigns } = useQuery(
    getCampaignListing,
    {
      fetchPolicy: "cache-first",
    }
  );

  const { data: segmentsData, loading: loadingSegments } = useQuery(listSegments);
  const { data: campaignData } = useQuery(loadCampaignById, {
    variables: { id: campaignId },
    skip: !campaignId,
  });

  useEffect(() => {
    if (campaignData?.loadCampaignById) {
      isOnPage
        ? setCampaignState(campaignData.loadCampaignById)
        : setStaticCampaignState(campaignData.loadCampaignById);
    }
  }, [campaignData, setCampaignState, isOnPage]);

  const getRuleData = (data) => {
    const limit = data?.limit;
    if (limit) return limit;
    if (data?.segment_ids)
      return data?.segment_ids?.length ? data.segment_ids.join(", ") : "NA";
    const days = data?.days ? data.days : data?.days === 0 ? "0" : "";
    const hours = data?.hours ? data.hours : data?.hours === 0 ? "0" : "";
    const minutes = data?.minutes ? data.minutes : data?.minutes === 0 ? "0" : "";
    const unitDays = days > 1 ? "days" : "day";
    const unitHours = hours > 1 ? "hrs" : "hr";
    const unitMinutes = minutes > 1 ? "mins" : "min";
    let period = days ? `${days} ${unitDays}` : "";
    period += days && hours ? ", " : "";
    period += hours ? `${hours} ${unitHours}` : "";
    period += (days || hours) && minutes ? ", " : "";
    period += minutes ? `${minutes} ${unitMinutes}` : "";
    period = period ? period : "0";
    period = data?.enabled === false ? "NA" : period;
    return period;
  };
  const campaignStateData = isOnPage ? campaignState : staticCampaignState;
  const contentData = campaignStateData?.campaignContent?.contentData;

  useEffect(() => {
    if (campaignStateData.segmentedContents && segmentsData) {
      const updatedSegmentedContents = campaignStateData.segmentedContents
        .filter((content) => content?.segments?.[0] !== "")
        .map((segmentId) => {
          const segment = segmentsData.listSegments.find(
            (seg) => seg.id === segmentId.segments?.[0]
          );
          return {
            ...segmentId,
            segmentName: segment?.name || "Unknown Segment",
          };
        });

      setSegmentedContentsData(updatedSegmentedContents);
    }
    const campaignRulesArray =
      campaignStateData?.campaignRules?.map((ruleData) => ({
        [ruleData?.ruleDefinition?.name]: getRuleData(ruleData?.ruleData),
      })) || [];
    setCampaignRulesData(campaignRulesArray);
  }, [campaignStateData, segmentsData]);

  const isViewScreen =
    location.pathname.includes("campaign-summary-view") || !isOnPage;

  const isProgramPTR = window.REACT_APP_PROGRAM === "ptr";

  const getBasePath = () => {
    const path = location.pathname.split("/");
    return path.slice(0, path.length - 1).join("/");
  };

  const handleBasicEditSection = () => {
    navigate(`${getBasePath()}/generic-setup`);
  };

  const handleActivityEditSection = () => {
    const isNewApproach =
      currentCampaignTypeConfig.steps?.some(
        (step) => step.slug === `${paths.acitivity_level_setup_new}`
      ) || false;

    if (isNewApproach) {
      navigate(`${getBasePath()}/activity-level-setup-new`);
    } else {
      navigate(`${getBasePath()}/activity-level-setup`);
    }
  };

  const handleOutcomesEditSection = () => {
    navigate(`${getBasePath()}/campaign-level-setup`);
  };

  const relatedCampaignsData =
    allCampaignsData?.listCampaigns?.filter((campaign) =>
      campaignStateData.relatedCampaigns?.includes(campaign.id)
    ) || [];

  return (
    <>
      {/* Basic Card */}
      <SummaryBasicSetup
        data={campaignStateData}
        isViewScreen={isViewScreen}
        editSectionHandler={handleBasicEditSection}
        inputSchema={inputSchema}
        contentSchema={contentSchema}
      />
      {/* Selected Contents */}
      {contentData?.l10n && (
        <LocalizationList
          data={contentData}
          isViewScreen={isViewScreen}
          contentSchema={contentSchema}
        />
      )}
      {contentData?.product_contents && (
        <ProductContentList
          editSectionHandler={handleBasicEditSection}
          data={contentData.product_contents}
          contentSchema={contentSchema?.definitions?.product_content}
        />
      )}

      {/* Segments/Linked Campaigns Section */}
      {(segmentedContentsData.length > 0 || relatedCampaignsData.length > 0) && (
        <>
          {loadingCampaigns || loadingSegments ? (
            <div className="loading-container">
              <LoadingSpinner size={{ height: "2rem", width: "2rem" }} />
            </div>
          ) : (
            <SegmentsLinkedCampaigns
              segmentedData={segmentedContentsData}
              relatedData={relatedCampaignsData}
              isViewScreen={isViewScreen}
              editSectionHandler={handleBasicEditSection}
            />
          )}
        </>
      )}

      {/* Activity Setup Section */}
      {campaignStateData?.activities && (
        <SummaryActivitySetup
          data={campaignStateData}
          isViewScreen={isViewScreen}
          editSectionHandler={handleActivityEditSection}
        />
      )}

      {campaignStateData?.outcomes?.length > 0 && (
        <CampaignOutcomes
          campaignStateData={campaignStateData}
          handleOutcomesEditSection={handleOutcomesEditSection}
          isViewScreen={isViewScreen}
        />
      )}

      {!isProgramPTR && campaignRulesData.length > 0 && (
        <SummaryCard
          title="Campaign Setup - Rules"
          isViewScreen={isViewScreen}
          handleEditSection={handleOutcomesEditSection}
        >
          {campaignRulesData?.map((rule) => (
            <div className="additional-fields-container" key={Object.keys(rule)[0]}>
              <div className="field-pair">
                <span className="field-label"> {Object.keys(rule)[0]}</span>
              </div>
              <div className="field-pair-two-column">
                <span className="field-value">{Object.values(rule)[0]}</span>
              </div>
            </div>
          ))}
        </SummaryCard>
      )}
    </>
  );
}
