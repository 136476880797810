import { useQuery } from "@apollo/client";
import { debounce } from "lodash";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { removeEmptyValues } from "../../../utils/commons";

const ListingComponentContext = createContext();

const ListingComponentProvider = ({
  children,
  query,
  fetchPolicy,
  queryKey,
  initialFilterData,
  noPagination,
}) => {
  const [filterData, setFilterData] = useState(initialFilterData || {});
  const [debouncedFilterData, setDebouncedFilterData] = useState({}); // separate state for debounced fields
  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowsCount: noPagination ? null : 20,
  });
  const allRecords = useRef([]);
  const [viewableRecords, setViewableRecords] = useState([]);

  const [viewType, setViewType] = useState("grid");

  const [totalPageCount, setTotalPageCount] = useState(0);
  const { data, loading, error, refetch } = useQuery(query, {
    variables: {
      filter: { ...filterData, ...debouncedFilterData },
      page: noPagination ? undefined : pagination.currentPage - 1,
    },
    fetchPolicy: fetchPolicy || "cache-and-network",
  });
  const filteredRecords = data?.[queryKey] || [];

  useEffect(() => {
    if (!allRecords.current?.length && data?.[queryKey]) {
      allRecords.current = data[queryKey];
    }
  }, [data, queryKey]);

  const resetPagination = () => {
    setPagination((state) => ({ ...state, currentPage: 1 }));
  };

  const hasItems = (obj) => {
    return obj.items && Array.isArray(obj.items);
  };

  const getTotalRecords = useCallback((filteredRecords, queryTotalRecords) => {
    const listedTotalRecords = hasItems(filteredRecords)
      ? filteredRecords.items.length
      : filteredRecords.length || 0;
    return queryTotalRecords || listedTotalRecords;
  }, []);

  const handleData = useCallback(
    (records) => {
      if (noPagination) {
        setViewableRecords(records);
      } else {
        const filteredRecords = records;
        const queryTotalRecords = filteredRecords.total;
        const start = queryTotalRecords
          ? 0
          : (pagination.currentPage - 1) * pagination.rowsCount;
        const end = start + pagination.rowsCount;
        const viewableRecords = hasItems(filteredRecords)
          ? filteredRecords.items.slice(start, end)
          : filteredRecords.slice(start, end);
        setViewableRecords(viewableRecords);
        const totalRecords = getTotalRecords(filteredRecords, queryTotalRecords);
        setTotalPageCount(Math.ceil(totalRecords / pagination.rowsCount));
      }
    },
    [noPagination, pagination, getTotalRecords]
  );

  useEffect(() => {
    if (data && data[queryKey]) {
      handleData(data[queryKey]);
    } else if (error) {
      setViewableRecords([]);
    }
  }, [data, error, noPagination, pagination, queryKey, handleData]);

  const delayedRefetch = useMemo(() => {
    return debounce((value) => {
      setDebouncedFilterData(removeEmptyValues({ ...filterData, ...value }));
      setPagination({ ...pagination, currentPage: 1 });
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);
  useEffect(() => {
    return () => delayedRefetch.cancel();
  }, [delayedRefetch]);

  const handleDebouncedFilterChange = (data) => {
    delayedRefetch(data);
  };

  const handleClearFilters = () => {
    setFilterData(initialFilterData);
    setDebouncedFilterData({});
    resetPagination();
  };

  return (
    <ListingComponentContext.Provider
      value={{
        filterData,
        debouncedFilterData,
        setDebouncedFilterData,
        handleDebouncedFilterChange,
        pagination,
        viewType,
        totalPageCount,
        setTotalPageCount,
        handleClearFilters,
        setFilterData,
        setViewType,
        setPagination,
        resetPagination,
        refetch,
        data,
        allRecords: allRecords.current,
        filteredRecords,
        viewableRecords,
        loading,
        error,
      }}
    >
      {children}
    </ListingComponentContext.Provider>
  );
};

export { ListingComponentContext, ListingComponentProvider };
