import { useQuery } from "@apollo/client";
import { Dropdown } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { RewardContext } from "../../../context/RewardContext";
import { listRewardCategories } from "../../../graphql/queries/listRewardCategories";

const RewardCategorySelect = ({ label, selected, required, readonly }) => {
  const {
    rewardState,
    addOptionLoading,
    removeOptionLoading,
    updateRewardState,
    setToast,
  } = useContext(RewardContext);
  const { loading } = useQuery(listRewardCategories, {
    variables: { size: 1000 },
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      if (data?.listRewardCategories) {
        const categoriesListOptions = data.listRewardCategories.items?.map(
          (category) => ({
            id: category.id,
            displayText: category.name,
          })
        );
        setCategoriesList(categoriesListOptions);
      }
    },
    onError: () => {
      setCategoriesList([]);
      setToast({
        open: true,
        type: "error",
        title: "Something went wrong while loading categories.",
        description: "Please try again later.",
      });
    },
  });
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    loading ? addOptionLoading("categories") : removeOptionLoading("categories");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (categoriesList.length) {
      const selectedCategoryOptions = categoriesList.filter((category) =>
        selected?.some((id) => id === category.id)
      );
      setSelectedCategories(selectedCategoryOptions);
    }
  }, [selected, categoriesList]);

  useEffect(() => {
    if (
      rewardState.rewardCategoryIds &&
      !(rewardState.category || rewardState.category === "")
    ) {
      updateRewardState({
        ...rewardState,
        category: rewardState.rewardCategoryIds.join(" "),
      });
    }
  }, [rewardState, updateRewardState]);

  return (
    <Dropdown
      isRequired={required}
      childList={categoriesList}
      style={{ minWidth: "0px" }}
      className="zIndexToDropDown"
      label={label}
      selection="multiple"
      selectedValue={selectedCategories}
      setSelectedValue={(val) => {
        const rewardCategoryIds = val?.map((category) => category.id) || [];
        updateRewardState({
          ...rewardState,
          rewardCategoryIds: rewardCategoryIds,
          category: rewardCategoryIds.join(" "),
        });
      }}
      size="medium"
      placeholder="Select"
      disabled={readonly}
    />
  );
};

export default RewardCategorySelect;
