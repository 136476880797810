import { gql } from "@apollo/client";

export const listCodeGroups = gql`
  query ListCodeGroups($filter: CodeGroupFilter) {
    listCodeGroups(filter: $filter) {
      codeGroupId
      codesUsedCount
      codesGeneratedCount
      administrationStatus
      compositeStatus
      lifecycleStatus
      exportUrl
      basicSetup {
        codeGroupName
        codeActiveStartDate
        codeActiveEndDate
      }
      producerPackaging {
        producerCompany
        codesPrintedCount
        brand
      }
    }
  }
`;

export const loadCodeGroup = gql`
  query LoadCodeGroup($id: ID!) {
    loadCodeGroup(id: $id) {
      codeGroupId
      exportUrl
      codesGeneratedCount
      administrationStatus
      compositeStatus
      lifecycleStatus
      basicSetup {
        codeGroupName
        codeActiveStartDate
        codeActiveEndDate
      }
      producerPackaging {
        brand
        producerCompany
        codesPrintedCount
        productVolume
        packageType
        productCapColor
      }
      dummyCodes {
        allowDummyCodes
        dummyCodeMessage
        dummyCodePercent
      }
      codeSpecifications {
        codeLength
        usePrefix
        prefixVal
        useSuffix
        suffixVal
        caseSensitive
        excludeCharacters
        excludeCharacterValues
      }
    }
  }
`;
