import { useQuery } from "@apollo/client";
import { Icon, LoadingSpinner, Tag } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { findCodeById } from "../../../graphql/queries/findCodeById";
import { getDateWithFormat } from "../../../utils/commons";

const CodeInformation = ({ searchText, setCodeGroupId }) => {
  const { t } = useTranslation();
  const [codeInformation, setCodeInformation] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const { programTimezone } = useContext(GlobalConfigContext);
  const [error, setError] = useState(null);

  const {
    data: codeGroupData,
    refetch: refetchCodeGroup,
    loading,
  } = useQuery(findCodeById, {
    variables: { code: searchText },
    onCompleted: () => {
      setError(null);
      setCodeInformation(codeGroupData?.findCodeById);
      setCodeGroupId(codeGroupData?.findCodeById?.codeGroup?.codeGroupId);
    },
    onError: (err) => {
      setCodeGroupId("");
      setError(`${err?.graphQLErrors?.[0]?.message || err?.message}`);
      console.error("Error fetching code group data:", err);
    },
  });

  useEffect(() => {
    setCodeGroupId("");
    refetchCodeGroup();
    setIsExpanded(false);
  }, [searchText, refetchCodeGroup, setCodeGroupId]);

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const userTypeLabelMap = {
    ZYNGA: "ZYNGA",
    CONSUMER: t("CustomerSupport:ConsumerLabel"),
    OTHER: t("CustomerSupport:OtherLabel"),
  };

  const renderCodeInfoRow = () => {
    if (error) {
      return (
        <tr>
          <td colSpan={8}>{error}</td>
        </tr>
      );
    }

    if (!codeInformation?.status) {
      return (
        <tr>
          <td colSpan={8}>{t("CustomerSupport:NoResultsFoundLabel")}</td>
        </tr>
      );
    }

    return (
      <tr onClick={toggleExpand}>
        <td>
          <Icon
            icon={isExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            title={isExpanded ? "Expand less" : "Expand more"}
            size="medium"
          />
        </td>
        <td>
          <Tag
            color={codeInformation?.status === "CLAIMED" ? "Green-150" : "Cyan-500"}
            text={
              codeInformation?.status === "CLAIMED"
                ? t("CustomerSupport:UsedLabel")
                : t("CustomerSupport:UnusedLabel")
            }
            isCopyable={false}
          />
        </td>
        <td>{codeInformation?.codeGroup?.basicSetup?.codeGroupName}</td>
        <td>{codeInformation?.campaign?.title || "-"}</td>
        <td>
          {codeInformation?.campaign?.activeStartDate
            ? getDateWithFormat(
                codeInformation?.campaign?.activeStartDate,
                programTimezone
              )
            : "-"}
        </td>
        <td>
          {codeInformation?.campaign?.activeEndDate
            ? getDateWithFormat(
                codeInformation?.campaign?.activeEndDate,
                programTimezone
              )
            : "-"}
        </td>
        <td>
          {codeInformation?.profile?.userInfo ? (
            <span>
              {codeInformation?.profile?.userInfo?.firstName}{" "}
              {codeInformation?.profile?.userInfo?.middleName}{" "}
              {codeInformation?.profile?.userInfo?.lastName}
            </span>
          ) : (
            <span>-</span>
          )}
        </td>
        <td>
          {codeInformation?.profile?.userInfo
            ? userTypeLabelMap[codeInformation?.userType] || "-"
            : "-"}
        </td>
      </tr>
    );
  };

  const renderRewardsTable = () => {
    if (!isExpanded) {
      return null;
    }
    if (!codeInformation?.rewards?.length > 0) {
      return (
        <tr>
          <td colSpan="8" className="text-center">
            {t("CustomerSupport:NoRewardsFoundLabel")}
          </td>
        </tr>
      );
    }
    return (
      <tr>
        <td colSpan="8" style={{ padding: 0 }}>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>{t("CustomerSupport:RewardColumn")}</th>
                <th>{t("CustomerSupport:WinDateColumn")}</th>
                <th>{t("CustomerSupport:RedemptionDateColumn")}</th>
              </tr>
            </thead>
            <tbody>
              {codeInformation?.rewards?.map((reward) => (
                <tr key={reward?.id}>
                  <td>{reward?.name}</td>
                  <td>
                    {reward?.createdDate
                      ? getDateWithFormat(reward?.createdDate, programTimezone)
                      : "-"}
                  </td>
                  <td>
                    {reward?.claimCreatedDate
                      ? getDateWithFormat(reward?.claimCreatedDate, programTimezone)
                      : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    );
  };

  return (
    <div className="list-container">
      {loading ? (
        <LoadingSpinner
          size={{ height: "30px", width: "30px" }}
          style={{ margin: "auto" }}
        />
      ) : (
        <table className="list-table">
          <thead>
            <tr>
              <th></th>
              <th>{t("CustomerSupport:StatusColumn")}</th>
              <th>{t("CustomerSupport:CodeGroupColumn")}</th>
              <th>{t("CustomerSupport:CampaignColumn")}</th>
              <th>{t("CustomerSupport:CampaignStartDateColumn")}</th>
              <th>{t("CustomerSupport:CampaignEndDateColumn")}</th>
              <th>{t("CustomerSupport:UserColumn")}</th>
              <th>{t("CustomerSupport:UserTypeColumn")}</th>
            </tr>
          </thead>
          <tbody>
            {renderCodeInfoRow()}
            {renderRewardsTable()}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CodeInformation;
