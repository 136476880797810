import { useQuery } from "@apollo/client";
import { listRewardGroups } from "../../../../graphql/queries/listRewardGroups";
import { getDateWithFormat } from "../../../../utils/commons";

const RewardOutcome = ({ outcome }) => {
  const { data } = useQuery(listRewardGroups, {
    variables: {
      rewardGroupId: outcome?.outcomeData?.reward_group_id,
    },
    skip: !outcome?.outcomeData?.reward_group_id,
  });
  const rewardGroupReward = data?.listRewardGroups?.[0]?.rewardGroupRewards?.[0];
  return (
    <>
      <div className="display-flex justify-content-space-between align-items-center w-100 font-bold pb-2">
        Reward Details
      </div>
      <hr style={{ marginBottom: "10px" }} color="#DFDFDF" />
      <div style={{ width: "100%" }}>
        <div className="additional-fields-container">
          <div className="field-pair">
            <span className="field-label">Quantity</span>
          </div>
          <div className="field-pair-two-column">
            <span className="field-value">{rewardGroupReward?.quantity}</span>
          </div>
        </div>
        <div className="additional-fields-container">
          <div className="field-pair">
            <span className="field-label">Wallet Withdrawal Date</span>
          </div>
          <div className="field-pair-two-column">
            <span className="field-value">
              {rewardGroupReward?.walletWithdrawalDate
                ? getDateWithFormat(
                    rewardGroupReward?.walletWithdrawalDate,
                    null,
                    "MM/DD/YYYY hh:mm a"
                  )
                : "N/A"}
            </span>
          </div>
        </div>
        <div className="additional-fields-container">
          <div className="field-pair">
            <span className="field-label">Reward Name</span>
          </div>
          <div className="field-pair-two-column">
            <span className="field-value">
              {rewardGroupReward?.rewardInfo?.name || rewardGroupReward?.rewardId}
            </span>
          </div>
        </div>
        <div className="additional-fields-container">
          <div className="field-pair">
            <span className="field-label">Reward Short Description</span>
          </div>
          <div className="field-pair-two-column">
            <span className="field-value">
              {rewardGroupReward?.rewardInfo?.shortDescription || "-"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default RewardOutcome;
