import { withJsonFormsControlProps } from "@jsonforms/react";
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { Button, IconButton, Popover, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import "./rangeFilter.scss";

const RangeFilterComponent = (props) => {
  const { data, handleChange, path, label, uischema } = props;
  const options = uischema.options || {};
  const buttonLabel = options.buttonLabel || label || "Set Range";
  const fromLabel = options.fromLabel || "From";
  const toLabel = options.toLabel || "To";
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromValue, setFromValue] = useState(data?.from || "");
  const [toValue, setToValue] = useState(data?.to || "");
  const open = Boolean(anchorEl);

  useEffect(() => {
    setFromValue(data?.from ?? "");
    setToValue(data?.to ?? "");
  }, [data]);

  const handleButtonClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleApply = () => {
    const parsedFromValue =
      fromValue !== null && fromValue !== undefined ? parseInt(fromValue, 10) : null;
    const parsedToValue =
      toValue !== null && toValue !== undefined ? parseInt(toValue, 10) : null;
    handleChange(path, { from: parsedFromValue, to: parsedToValue });
    setAnchorEl(null);
  };
  const handleClear = () => {
    setFromValue("");
    setToValue("");
    handleChange(path, { from: null, to: null });
    setAnchorEl(null);
  };
  const hasValue =
    (fromValue !== "" && fromValue !== null && fromValue !== undefined) ||
    (toValue !== "" && toValue !== null && toValue !== undefined);

  const buttonVariant = hasValue ? "contained" : "outlined";

  return (
    <div className="range-filter">
      <Button variant={buttonVariant} onClick={handleButtonClick} size="small">
        {buttonLabel}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{ className: "range-filter-popover" }}
      >
        <div className="popover-content">
          <div className="input-container">
            <TextField
              label={fromLabel}
              type="number"
              value={fromValue}
              onChange={(e) => setFromValue(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                inputProps: { min: 0 },
                sx: {
                  padding: "0px 0px", // Reduce padding inside the input
                  fontSize: "small", // Smaller font size
                },
              }}
            />
            <TextField
              label={toLabel}
              type="number"
              value={toValue}
              onChange={(e) => setToValue(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                inputProps: { min: 0 },
                sx: {
                  padding: "0px 0px", // Reduce padding inside the input
                  fontSize: "small", // Smaller font size
                },
              }}
            />
          </div>
          <div className="icon-container">
            {/* Clear Icon */}
            <IconButton
              onClick={handleClear}
              className="icon-button"
              aria-label="Clear"
            >
              <CancelIcon className="clear-icon" />
            </IconButton>
            {/* Apply Icon */}
            <IconButton
              onClick={handleApply}
              className="icon-button"
              aria-label="Apply"
            >
              <CheckCircleIcon className="apply-icon" />
            </IconButton>
          </div>
        </div>
      </Popover>
    </div>
  );
};

RangeFilterComponent.propTypes = {
  data: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  label: PropTypes.string,
  uischema: PropTypes.object.isRequired,
};

export const RangeFilterControl = withJsonFormsControlProps(RangeFilterComponent);
