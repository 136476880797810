import { useJsonForms } from "@jsonforms/react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone";
import "../../common/DatePickerMaterialUI/DatePickerMaterialUI.scss";

moment.locale("en");

export default function DatePickerMaterialUIField(props) {
  const {
    value,
    updateValue,
    uiSchema,
    label,
    visible,
    placeholder,
    disabled,
    timezone,
    readOnly,
  } = props;

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString();
      updateValue(formattedDate);
    } else {
      updateValue(null);
    }
  };
  const ctx = useJsonForms();
  const jsonformState = ctx.core.data;

  const minDate = jsonformState?.[uiSchema.minimum]
    ? moment.tz(jsonformState?.[uiSchema.minimum], timezone)
    : null;

  const maxDate = jsonformState?.[uiSchema.maximum]
    ? moment.tz(jsonformState?.[uiSchema.maximum], timezone)
    : null;

  return visible ? (
    <>
      <div className="date-picker-material-ui-wrap">
        {label && <label htmlFor={`datePicker-${label}`}>{label}</label>}
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          dateLibInstance={moment}
          adapterLocale="en"
        >
          <DatePicker
            className="customDatePickerStyles"
            value={value ? moment.tz(value, timezone) : null}
            onChange={handleDateChange}
            minDate={minDate}
            maxDate={maxDate}
            label={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            format="DD/MM/YYYY"
            timezone={timezone}
          />
        </LocalizationProvider>
      </div>
    </>
  ) : null;
}
