import { useLazyQuery, useQuery } from "@apollo/client";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { debounce } from "lodash";
import { Card, Font, Icon, InputItem, LoadingSpinner } from "pepsico-ds";
import { useCallback, useEffect, useState } from "react";
import { listRewards } from "../../graphql/queries/listRewards";
import { listRewardTypes } from "../../graphql/queries/listRewardTypes";
import { useListPartnersQuery } from "../../hooks/useListPartnersQuery";
import { SingleBorderCard } from "../common/SingleBorderCard";
import "./AddRewards.scss";
import SelectedRewards from "./SelectedRewards";

const FilterItem = ({ text, isSelected = false, id, onClick }) => {
  return (
    <Font size="xsmall" onClick={() => onClick(id)} className="filter-item">
      {text}
      {isSelected ? <Icon alt="check icon" icon="checkmark" size="small" /> : null}
    </Font>
  );
};

export default function AddRewards({
  value = [],
  updateValue,
  type,
  activeStartDate,
  isSpinWheelEnabled,
}) {
  const [callListRewards, { data, loading }] = useLazyQuery(listRewards);
  const [searchText, setSearchText] = useState("");
  const [selectedPartner, setSelectedPartner] = useState("");
  const [selectedRewardType, setSelectedRewardType] = useState([]);
  const { data: listPartners } = useListPartnersQuery("", 0, 1000);
  const { data: dataRewardTypes, loading: loadingRewardTypes } = useQuery(
    listRewardTypes,
    {
      skip: selectedPartner === "",
      variables: {
        partnerId: selectedPartner,
      },
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callAfterChange = useCallback(
    debounce(
      (isSpinWheelEnabled, searchText, selectedRewardType, selectedPartner) => {
        const filter = { isActive: true };
        if (isSpinWheelEnabled) {
          filter.hasWheelImage = true;
        }
        filter.name = searchText;
        if (selectedPartner) {
          filter.partnerIds = [selectedPartner];
        }
        if (selectedRewardType?.length) {
          filter.rewardTypeIds = selectedRewardType;
        }
        callListRewards({
          variables: { filter, page: 0, size: 1000 },
          fetchPolicy: "no-cache",
        });
      },
      500
    ),
    []
  );

  useEffect(() => {
    callAfterChange(
      isSpinWheelEnabled,
      searchText,
      selectedRewardType,
      selectedPartner
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpinWheelEnabled, searchText, selectedRewardType, selectedPartner]);

  const onRewardSelect = (reward) => {
    const isSelected = value.find((item) => item.rewardId === reward.id);
    if (isSelected) {
      updateValue(value.filter((item) => item.rewardId !== reward.id));
    } else {
      updateValue([
        ...value,
        {
          rewardId: reward.id,
          name: reward.name,
          rewardInfo: { name: reward.name, wheelImageUrl: reward.wheelImageUrl },
        },
      ]);
    }
  };

  const onRemoveSelectedReward = (reward) => {
    updateValue(
      value.filter((item) => item.rewardId !== reward.rewardId),
      true,
      reward
    );
  };

  const updateRewardData = (reward) => {
    updateValue(
      value?.map((item) => {
        if (item.rewardId === reward.rewardId) {
          return { ...reward };
        }
        return item;
      })
    );
  };

  const handleSelectPartner = (id) => {
    setSelectedPartner(id);
    setSelectedRewardType([]);
  };

  const handleSelectRewardType = (id) => {
    if (selectedRewardType.includes(id)) {
      const listWithRemovedId = selectedRewardType.filter(
        (rewardTypeId) => rewardTypeId !== id
      );
      setSelectedRewardType(listWithRemovedId);
    } else {
      setSelectedRewardType((state) => [...state, id]);
    }
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const newValue = Array.from(value);
    const [draggedItem] = newValue.splice(result.source.index, 1);
    newValue.splice(result.destination.index, 0, draggedItem);
    updateValue(newValue);
  }

  return (
    <div className="mt-2">
      <Grid container columnSpacing={2}>
        <Grid item xs={3}>
          <Card
            icon=""
            ellipsis=""
            style={{
              // padding: "0px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              width: "100%",
              height: "100%",
            }}
          >
            <h4 className="font-md">Find Reward</h4>
            <InputItem
              placeholder="Search by keyword"
              trailingIcon="search"
              size="medium"
              style={{
                borderRadius: 0,
                border: "none",
                borderBottom: "1px solid #c0c0c0",
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Accordion className="campaign-add-rewards-filter">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="display-flex justify-content-space-between align-items-center w-100">
                  <span>Partner</span>
                  {selectedPartner && (
                    <ClearIcon
                      sx={{
                        height: "14px",
                        width: "14px",
                        cursor: "pointer",
                        marginRight: "8px",
                      }}
                      title="Clear"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPartner("");
                        setSelectedRewardType([]);
                      }}
                    />
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {listPartners?.map((option) => (
                  <FilterItem
                    text={option?.name}
                    key={option?.id}
                    id={option?.id}
                    isSelected={option?.id === selectedPartner}
                    onClick={handleSelectPartner}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion className="campaign-add-rewards-filter">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="display-flex justify-content-space-between align-items-center w-100">
                  <span>Reward type</span>
                  {selectedRewardType?.length > 0 && (
                    <ClearIcon
                      sx={{
                        height: "14px",
                        width: "14px",
                        cursor: "pointer",
                        marginRight: "8px",
                      }}
                      title="Clear"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRewardType([]);
                      }}
                    />
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {loadingRewardTypes ? (
                  <section
                    className="loading-rewards"
                    data-testid="loading-types-spinner"
                  >
                    <LoadingSpinner
                      size={{
                        height: "32px",
                        width: "32px",
                      }}
                    />
                  </section>
                ) : (
                  <>
                    {dataRewardTypes?.listRewardType?.items?.length > 0 ? (
                      <>
                        {dataRewardTypes?.listRewardType?.items?.map(
                          (rewardType) => (
                            <FilterItem
                              text={rewardType?.name}
                              key={rewardType?.id}
                              id={rewardType?.id}
                              isSelected={selectedRewardType.includes(
                                rewardType?.id
                              )}
                              onClick={handleSelectRewardType}
                            />
                          )
                        )}
                      </>
                    ) : (
                      <Font size="small" className="not-found">
                        No Reward Types found
                      </Font>
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            {loading && (
              <Grid item xs={12} data-testid="loading-spinner">
                <LoadingSpinner
                  size={{
                    height: "40px",
                    width: "40px",
                  }}
                />
              </Grid>
            )}

            {!loading && data?.listRewards?.items?.length === 0 && (
              <Grid item xs={12}>
                <h4 className="font-md">No rewards found</h4>
              </Grid>
            )}

            {!loading &&
              data?.listRewards?.items?.length > 0 &&
              data?.listRewards?.items?.map((reward) => (
                <Grid
                  item
                  xs={6}
                  md={4}
                  // lg={3}
                  xl={3}
                  className="cursor-pointer"
                  key={`${reward.id}_${Math.floor(1000 + Math.random() * 9000)}`}
                  onClick={() => onRewardSelect(reward)}
                >
                  <SingleBorderCard>
                    <img
                      src={reward.imageUrl}
                      height={200}
                      style={{
                        borderRadius: "6px",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                    <h4
                      className="font-sm text-wrap text-ellipsis-2"
                      style={{ minHeight: "35px" }}
                      title={reward.name}
                    >
                      {reward.name}
                    </h4>
                    <p
                      className="font-sm text-wrap text-ellipsis-2"
                      style={{ minHeight: "35px", color: "#616161" }}
                      title={reward.shortDescription}
                    >
                      {reward.shortDescription}
                    </p>
                    {!!value.find((item) => {
                      return item.rewardId === reward.id;
                    }) && (
                      <img
                        src={"/assets/images/checkmark.png"}
                        height={24}
                        width={24}
                        style={{ position: "absolute", right: 10, top: 10 }}
                      />
                    )}
                  </SingleBorderCard>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <div style={{ position: "sticky", left: 0, top: "5px" }}>
            <SelectedRewards
              value={value}
              onRewardSelect={onRemoveSelectedReward}
              updateRewardData={updateRewardData}
              type={type}
              handleOnDragEnd={handleOnDragEnd}
              activeStartDate={activeStartDate}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
