import CheckIcon from "@mui/icons-material/Check";
import PropTypes from "prop-types";
import { getDateWithFormat } from "../../../utils/commons";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import { partnersCodeGroupActionButtons } from "../partnersCodeGroupActionButton";
import "./PartnerCodeGroupTable.scss";

const PartnerCodeGroupTable = ({
  partnerCodeGroupData,
  handleActionButtons,
  currentProgramTimezone,
}) => {
  /* We need this code for future change so commented */
  /* const getStatus = (status, startDate, endDate) => {
    if (
      new Date(moment.tz(startDate, currentProgramTimezone)) >
      new Date(moment.tz(currentProgramTimezone))
    ) {
      return "imported";
    }
    if (
      new Date(moment.tz(endDate, currentProgramTimezone)) <
      new Date(moment.tz(currentProgramTimezone))
    ) {
      return "expired";
    }
    return status ? "active" : "anactive";
  }; */

  return (
    <div className="pcg-list-container">
      <table className="pcg-list-table">
        <thead>
          <tr>
            <th className="column-status">Status</th>
            <th className="column-code-status">Code Imported</th>
            <th className="column-title">Code Group Name</th>
            <th className="column-description">Description</th>
            <th className="column-active-date">Active Date</th>
            <th className="column-inactive-date">Import Date</th>
            <th className="column-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {partnerCodeGroupData.map((item, index) => (
            <tr key={index}>
              <td className="pcg-status-type">
                <span
                  className={`status ${item?.status === "ACTIVE" ? "active" : "inactive"}`}
                >
                  {item?.status}
                </span>
              </td>
              <td className="pcg-status-type code-status">
                {item?.status ? (
                  <span
                    className={
                      item?.processingStatus === "IMPORTED"
                        ? "status active"
                        : "status inactive"
                    }
                  >
                    {item?.processingStatus === "IMPORTED" ? (
                      <>
                        {item?.processingStatus} <CheckIcon fontSize="small" />
                      </>
                    ) : (
                      item?.processingStatus
                    )}
                  </span>
                ) : (
                  "Not Imported"
                )}
              </td>
              <td className={`${/\s/.test(item.name) ? "" : "no-space-title"}`}>
                {item.name || "-"}
              </td>
              <td>{item?.description || "-"}</td>
              <td data-testid="activeDate">
                {item?.startDate
                  ? getDateWithFormat(item?.startDate, currentProgramTimezone)
                  : "-"}
                {" - "}
                {item?.endDate
                  ? getDateWithFormat(item?.endDate, currentProgramTimezone)
                  : "-"}
              </td>
              <td data-testid="processingStatus">
                {item?.processingStatus === "IMPORTED"
                  ? new Date(item?.importedDate).toLocaleDateString()
                  : "Processing"}
              </td>
              <td>
                <div className="button-group-wrapper">
                  <ButtonGroupDynamic
                    items={partnersCodeGroupActionButtons(
                      item?.status,
                      handleActionButtons,
                      item
                    )}
                    spacing="0px"
                    size="medium"
                    variant="control"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

PartnerCodeGroupTable.propTypes = {
  partnerCodeGroupData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      partnerId: PropTypes.string.isRequired,
      programId: PropTypes.string.isRequired,
      codeWarningLimitPercentage: PropTypes.number,
      isActive: PropTypes.bool,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      codeCount: PropTypes.number,
      exhaustedCode: PropTypes.number,
      isImported: PropTypes.bool,
      importedDate: PropTypes.string,
      processingStatus: PropTypes.string.isRequired,
      status: PropTypes.string,
    })
  ).isRequired,
};

export default PartnerCodeGroupTable;
