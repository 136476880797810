import PropTypes from "prop-types";
import { getDateWithFormat } from "../../utils/commons";
import { CODE_GROUP_LIFE_CYCLE_STATUS } from "../../utils/constants";
import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
import { codeGroupActionButton } from "./codeGroupActionButton";
import "./codeGroupTable.scss";

const CodeGroupTable = ({
  codeGroupData,
  handleActionButtons,
  downloadPermission,
  currentProgramTimezone,
  selectedItems,
  onSelect,
}) => {
  const allowedStatuses = [
    CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT,
    CODE_GROUP_LIFE_CYCLE_STATUS.INACTIVE,
  ];
  return (
    <div className="codegroup-container">
      <div className="codegroup-list-container">
        <table className="codegroup-list-table">
          <thead>
            <tr>
              <th></th>
              <th className="codegroup-column-status">Status</th>
              <th className="codegroup-column-title">Name</th>
              <th className="codegroup-column-description">Producer Company</th>
              <th className="codegroup-column-total-codes">Code Count</th>
              <th className="codegroup-column-total-codes">Used Code Count</th>
              <th className="codegroup-column-active-date">Start Date</th>
              <th className="codegroup-column-active-date">End Date</th>
              <th className="codegroup-column-actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            {codeGroupData.map((item) => {
              const basicSetup = item.basicSetup || {};
              const producerPackaging = item.producerPackaging || {};

              const codeGroupName = basicSetup.codeGroupName || "-";
              const codeActiveStartDate = basicSetup.codeActiveStartDate || null;
              const codeActiveEndDate = basicSetup.codeActiveEndDate || null;

              const producerCompany = producerPackaging.producerCompany || "-";
              const codesUsedCount =
                item.codesUsedCount !== undefined && item.codesUsedCount !== null
                  ? item.codesUsedCount
                  : "-";
              const codesGeneratedCount =
                item.codesGeneratedCount !== undefined &&
                item.codesGeneratedCount !== null
                  ? item.codesGeneratedCount
                  : "-";
              const compositeStatus = item.compositeStatus || "-";
              const compositeStatusClass = compositeStatus
                .toLowerCase()
                .replace(/\s/g, "-");

              const canSelect = allowedStatuses.includes(item.compositeStatus);
              const isSelected = selectedItems.includes(item.codeGroupId);

              return (
                <tr key={item.codeGroupId}>
                  {/* Checkbox cell */}
                  <td>
                    {canSelect ? (
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={(e) =>
                          onSelect(item.codeGroupId, e.target.checked)
                        }
                      />
                    ) : null}
                  </td>
                  <td className="codegroup-widget-status-type">
                    <span
                      className={`codegroup-lifecycle-status ${compositeStatusClass}`}
                    >
                      {compositeStatus}
                    </span>
                  </td>
                  <td
                    className={`${
                      /\s/.test(codeGroupName) ? "" : "codegroup-no-space-title"
                    }`}
                  >
                    {codeGroupName}
                  </td>
                  <td>{producerCompany}</td>
                  <td>{codesGeneratedCount}</td>
                  <td>{codesUsedCount}</td>
                  <td>
                    {codeActiveStartDate
                      ? getDateWithFormat(
                          codeActiveStartDate,
                          currentProgramTimezone
                        )
                      : "-"}
                  </td>
                  <td>
                    {codeActiveEndDate
                      ? getDateWithFormat(codeActiveEndDate, currentProgramTimezone)
                      : "-"}
                  </td>
                  <td>
                    <div className="codegroup-button-group-container">
                      <ButtonGroupDynamic
                        items={codeGroupActionButton(
                          item,
                          handleActionButtons,
                          downloadPermission
                        )}
                        spacing="0px"
                        size="medium"
                        variant="control"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

CodeGroupTable.propTypes = {
  codeGroupData: PropTypes.arrayOf(
    PropTypes.shape({
      codeGroupId: PropTypes.string.isRequired,
      codesUsedCount: PropTypes.number,
      codesGeneratedCount: PropTypes.number,
      administrationStatus: PropTypes.string,
      compositeStatus: PropTypes.string,
      lifecycleStatus: PropTypes.string,
      basicSetup: PropTypes.shape({
        codeGroupName: PropTypes.string,
        codeActiveStartDate: PropTypes.string,
        codeActiveEndDate: PropTypes.string,
      }),
      producerPackaging: PropTypes.shape({
        producerCompany: PropTypes.string,
        codesPrintedCount: PropTypes.number,
        brand: PropTypes.string,
      }),
    })
  ).isRequired,
  handleActionButtons: PropTypes.func.isRequired,
};

export default CodeGroupTable;
